import { Pipe, PipeTransform } from '@angular/core';

import { Product } from '../../models/product.model';

function parseAttributes(product: Product) {
  const attributes = [];

  for (let key in product.attributes.quality) {
    if (product.attributes.quality[key]) {
      attributes.push({
        key: key,
        data: product.attributes.quality[key]
      });
    }
  }

  attributes.sort((a, b) => {
    return parseInt(a.data.order) - parseInt(b.data.order);
  });

  return attributes;
}

/**
 * Converts quality attributes of a product into a formatted string.
 *
 * @param {any} [value={}] - An object containing quality data, organized by keys.
 * Each key represents an attribute with associated data (e.g., slug, abbrev, or name).
 * @param {Product} [product] - The product object containing metadata and attributes.
 * This includes an `attributes.quality` property for filtering quality-specific data.
 * @returns {string} - A string representation of the product's quality attributes,
 * formatted and separated by a delimiter (comma or semicolon).
 */
export function qualityToString(value: any = {}, product?: Product): string {
  if (!value || !product || !product.attributes || !product.attributes.quality) {
    return '';
  }

  // Parse product attributes once
  const attributes = parseAttributes(product);

  // Process attributes in a single pass
  const qualityStrings = attributes.reduce((acc: string[], attr) => {
    const attributeValue = value[attr.key];

    // Skip if the attribute value is not present or meets exclusion criteria
    if (!attributeValue || (attr.data.empty && attr.data.empty.slug === attributeValue.slug)) {
      return acc;
    }

    let str = '';
    if (attr.key === 'quality_specs' || attr.key === 'packaging') {
      // Special formatting for specific keys
      str = (attr.data.abbrev ? `${attr.data.abbrev} ` : '') +
        attributeValue.replace(/(\r\n|\n|\r)/gm, ' ');
      acc.push(str);
    } else {
      // General formatting for other keys
      str = `${attr.data.abbrev} ${attributeValue.abbrev || attributeValue.name}`;
      acc.push(str);
    }

    return acc;
  }, []);

  // Determine the separator dynamically based on processed attributes
  const separator = qualityStrings.some(str => str.includes(';')) ? ';' : ',';

  // Join and return the result
  return qualityStrings.join(separator + ' ');
}

export function qualityInfo(value: any = {}, product?: Product): string {
  let quality = [];

  if (product && product.attributes && product.attributes.quality) {
    const attributes = parseAttributes(product);

    quality = attributes.filter(attr => {
      return value && value[attr.key] &&
        (!value || !attr.data.empty || attr.data.empty.slug !== value[attr.key].slug);
    }).map(attr => {
      if (attr.key === 'quality_specs' || attr.key === 'packaging') {
        return (attr.data.name ? attr.data.name : '') + ': ' + value[attr.key];
      } else {
        return attr.data.name + ': ' + value[attr.key].name;
      }
    });
  }

  return quality.join('<br>');
}

@Pipe({
  name: 'quality'
})
export class QualityPipe implements PipeTransform {

  transform(value: any = {}, product?: Product): any {
    return qualityToString(value, product);
  }
}
